import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { environment } from 'environments/environment';
import { TranslateService } from '../translate/translate.service';

@Injectable({
  providedIn: 'root',
})
export class WikiService {
  private baseURL: string;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private translateService: TranslateService
  ) {
    // Comprobar si es entorno local o desplegado
    if (environment.local == true) {
      this.baseURL = `${environment.localUrl}:${environment.localPort}`;
    } else {
      this.baseURL = `${environment.url}`;
    }
  }

  getWikiItems(page: number = 0, limit: number = 10): Observable<any[]> {
    const language = this.translateService.getCurrentLanguage();
    const url = `${this.baseURL}${environment.api_wiki}?channel_id=2&lang=${language}&page=${page}&limit=${limit}`;
    console.log('Request URL:', url);

    return this.http.get<any>(url, { headers: this.authService.getHeadersWithToken() }).pipe(
      map(response => {
        if (response && response.data && response.data[0]?.wiki) {
          return response.data[0].wiki.map((wiki: any) => {
            const langData = wiki.lang.find((langObj: any) => langObj[language.toUpperCase()]);
            return langData ? { ...wiki, ...langData[language.toUpperCase()] } : wiki;
          });
        }
        throw new Error('Invalid response structure');
      }),
      catchError((error: HttpErrorResponse) => {
        console.error('Error fetching wiki items:', error.message);
        if (error.status === 500) {
          console.error('Server error details:', error.error);
        }
        return throwError(error);
      })
    );
  }
}