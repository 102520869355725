<!-- 
<div class="login-container">
  <mat-card>
    <mat-card-content>
      <form #loginForm="ngForm" (ngSubmit)="login()">
        <img
          src="../../../assets/pecera_logo.png"
          class="header-logo"
          alt="logo"
        />
        <h1>BeeHub</h1>
        <mat-error *ngIf="!loginValid">
          {{ translateService.translate("LOGIN.ERRORS.WRONG_CREDENTIALS") }}
        </mat-error>
        <mat-form-field>
          <input
            matInput
            placeholder="Usuario"
            [(ngModel)]="username"
            name="username"
            required
          />
          <mat-error>
            {{ translateService.translate("LOGIN.ERRORS.NOT_REGISTERED") }}
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <input
            matInput
            type="password"
            placeholder="Contraseña"
            [(ngModel)]="password"
            name="password"
            required
          />
          <mat-error>
            {{ translateService.translate("LOGIN.ERRORS.WRONG_PASSWORD") }}
          </mat-error>
        </mat-form-field>
        <br />
        <br />
        <button
          mat-raised-button
          color="primary"
          [disabled]="!loginForm.form.valid"
        >
          {{ translateService.translate("LOGIN.ERRORS.LOGIN") }}
        </button>
        <br />
        <br />

        <asl-google-signin-button
          *ngIf="!loggedIn"
          style="display: none"
          type="icon"
          size="medium"
        ></asl-google-signin-button>
      </form>
    </mat-card-content>
  </mat-card>
</div> 
-->
<div class="login-container">
  <!-- Logo -->
  <div class="logo-container">
    <img src="../../../assets/pecera_logo.png" class="header-logo" alt="logo" />
  </div>
  <!-- Login Title -->
  <h2>{{ translateService.translate("LOGIN.WELCOME") }} {{ appName }}</h2>
  <p class="description">
    {{ translateService.translate("LOGIN.GOOGLE_DESCRIPTION") }}
  </p>
  <!-- Google SSO Button -->
  <asl-google-signin-button
    *ngIf="!loggedIn"
    style="display: inline-block"
    type="icon"
    size="large"
  ></asl-google-signin-button>
  <!-- Support Links -->
  <div class="support-links">
    <a href="#"> {{ translateService.translate("LOGIN.PROBLEMS_LOGIN") }} </a> |
    <a href="#"> {{ translateService.translate("LOGIN.POLICY") }} </a>
  </div>
</div>
