import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DataShareService {
  // Declaración de BehaviorSubjects para distintos tipos de datos
  private rankingListSubject = new BehaviorSubject<any>([]);
  private userProfileSubject = new BehaviorSubject<any>([]);
  public updateAuthToken = new BehaviorSubject<any>([]);
  private noticiaSubject = new BehaviorSubject<any>(null);
  private retoSubject = new BehaviorSubject<any>(null);
  private activeChallengeNameSubject = new BehaviorSubject<string | null>(null);
  private selectedWikiSectionIndexSubject = new BehaviorSubject<number | null>(null); 
  private showMainSubject = new BehaviorSubject<boolean | null>(null); 
   
  // Exponer los BehaviorSubjects como Observables
  rankingList$ = this.rankingListSubject.asObservable();
  userProfile$ = this.userProfileSubject.asObservable();
  updateAuthToken$ = this.updateAuthToken.asObservable();
  noticia$ = this.noticiaSubject.asObservable();
  reto$ = this.retoSubject.asObservable();
  activeChallengeName$ = this.activeChallengeNameSubject.asObservable();
  selectedWikiSectionIndex$ = this.selectedWikiSectionIndexSubject.asObservable();

  // Métodos para actualizar los valores de los BehaviorSubjects
  setUserProfile(data: any) {
    this.userProfileSubject.next(data);
  }

  setUpdateAuthToken(data: any) {
    this.updateAuthToken.next(data);
  }

  updateShowMain(showMain: any) {
    this.showMainSubject.next(showMain);
  }

  updateRankingList(newList: any) {
    this.rankingListSubject.next(newList);
  }

  setNoticia(noticia: any) {
    this.noticiaSubject.next(noticia);
  }

  setReto(reto: any) {
    this.retoSubject.next(reto);
  }

  setUpdateShowMain(showMain: boolean | null) { 
    this.showMainSubject.next(showMain);
  }

  getUpdateShowMain(): Observable<any> {
    return this.showMainSubject;
  }
  setActiveChallengeName(name: string | null) { 
    this.activeChallengeNameSubject.next(name);
  }

  setSelectedWikiSectionIndex(index: number | null) { 
    const currentIndex = this.selectedWikiSectionIndexSubject.getValue();
    if (currentIndex !== index) {
      this.selectedWikiSectionIndexSubject.next(index);
    }
  }
  getSelectedWikiSectionIndex(): number | null {
    const index = this.selectedWikiSectionIndexSubject.getValue();
    this.clearSelectedWikiSectionIndex(); 
    return index;
  }
  clearSelectedWikiSectionIndex() {
    this.selectedWikiSectionIndexSubject.next(null);
  }
}
