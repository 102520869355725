// noticia.component.ts
import { Component, Input, OnInit } from '@angular/core';
import { AngularMaterialModule } from 'app/module/material/material-module';
import { TranslateService } from '@services/translate/translate.service';
@Component({
  selector: 'noticia',
  templateUrl: './noticia.component.html',
  styleUrls: ['./noticia.component.scss'],
  standalone: true,
  imports: [AngularMaterialModule],
})
export class NoticiaComponent implements OnInit {
  @Input() noticia: any;
  @Input() showAllNews: boolean = false;
  userLang = '';
  noticiaLang: any = [];
  constructor(public translateService: TranslateService) {}
  ngOnInit() {
  }
}
