import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class ParticipantesService {
  baseURL: string;
  
  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) {
    if (environment.local == true) {
      this.baseURL = `${environment.localUrl}:${environment.localPort}`;
    } else {
      // cuando esté desplegado
      this.baseURL = `${environment.url}`;
    }
  }

  // Método para obtener usuarios desde el endpoint real
  getUsuarios(): Observable<any> {
    const url = `${this.baseURL}${environment.api_ranking}?channel_id=2`;
    console.log('Request URL:', url);
    return this.http.get<any>(url, {
      headers: this.authService.getHeadersWithToken(),
    });
  }
}
