import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { DataShareService } from '@services/data-share/data-share.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  baseURL: string;
  public isAuth = new BehaviorSubject<boolean>(false);
  
  constructor(
    private router: Router,
    private http: HttpClient,
    private dataShare: DataShareService
  ) {
    if (environment.local == true) {
      this.baseURL = `${environment.localUrl}:${environment.localPort}`;
    } else {
      // when is deployed
      this.baseURL = `${environment.url}`;
    }
  }

  /**
   *
   * @returns the generated headers with the active access token
   */
  getHeadersWithToken(noAuthorization?: boolean): any {
    const socialUser = localStorage.getItem('socialUser') || '';
    if (socialUser) {
      var person = JSON.parse(socialUser);
    }    
    let headers = {};

    if (person.idToken) {
      headers = this.getHeaders(person, noAuthorization);
    } else {
      console.log('*****ACCESS TOKEN NOT FOUND*****');
      this.router.navigateByUrl('/login');
    }

    return headers;
  }

  /**
   *
   * @param params active access token
   * @returns
   */
  getHeaders(params: string, noAuthorization?: boolean): any {
    let headers: any;
    if (params) {
      const loggedUser = localStorage.getItem('loggedInUser')
        ? localStorage.getItem('loggedInUser')
        : null;
      let accessToken = '';
      if (loggedUser) {
        accessToken = JSON.parse(loggedUser).token;
      }

      headers = {
        Authorization: `${accessToken}`,
        ChannelToken: `Bearer ${environment.channelToken}`,
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': '*',
        Accept: 'application/json, */*',
      };
      if (noAuthorization && headers?.Authorization) {
        delete headers.Authorization;
      }
      //when token is invalid
    } else {
      //this.snackBar.open('INVALID TOKEN', '', { duration: 5000 });
      console.error('*****INVALID TOKEN*****');
      this.router.navigateByUrl('/login');
    }
    return headers;
  }

  /**
   *
   * @param userId
   * @returns the data containing all the user company info
   */
  getCompanyData(userId: string) {
    const endpoint = `/endpointurl/companydata/${userId}`;
    return this.http.get(this.baseURL + endpoint, {
      headers: this.getHeadersWithToken(),
    });
  }

  /**
   *http://localhost:8000/api/v1/company/users?channel_id=2&role=1
   * @param userId
   * @returns the data containing all the user company info
   */
  getCompanyUsers(userId: string) {
    const endpoint = `${environment.api_company}company/users?channel_id=2&role=1`;
    return this.http.get(this.baseURL + endpoint, {
      headers: this.getHeadersWithToken(),
    });
  }

  /**
   *
   * @param userId
   * @returns all the data needed to display the basic user profile info
   */
  getCompanyUserProfile(userId: string) {
    const endpoint = `/endpointurl/userprofile/${userId}`;
    return this.http.get(this.baseURL + endpoint, {
      headers: this.getHeadersWithToken(),
    });
  }

  /**
  http://localhost:8000/api/v1/user/loginsocial?channel_id=2&socialToken=**tokenhere**&driver=google
   * @param socialToken
   * @returns the auth token
   */
  getAuthToken(socialToken: string): Observable<any> {
    const headers = this.getHeadersWithToken();
    const body = {
      channel_id: 2,
      socialToken: socialToken,
      driver: 'google',
    };
    const endpoint = `${environment.api_user}loginsocial`;
    return this.http.post(this.baseURL + endpoint, body, {
      headers: headers,
    });
  }

  /**
   *
   * @param authToken
   * @returns the refresh token
   */
  getRefreshToken(authToken: string) {
    const headers = {};
    const endpoint = `/endpointurl/getrefreshtoken/${authToken}`;
    return this.http.get(this.baseURL + endpoint, {
      headers: headers,
    });
  }

  /**
   *http://localhost:8000/api/v1/user/refreshtoken
   *http://localhost:8000/api/v1/user/refreshtoken?channel_id=2&refresh_token=a
   * @returns
   */

  refreshToken() {
    //triggers the update token
    this.dataShare.setUpdateAuthToken(true);
    let refreshToken = '';
    const loggedUser = localStorage.getItem('loggedInUser')
      ? localStorage.getItem('loggedInUser')
      : null;
    if (loggedUser) {
      refreshToken = JSON.parse(loggedUser).refresh_token;
    }

    const noAuthorization = true;
    const body = {
      channel_id: 2,
      refresh_token: refreshToken,
    };

    const endpoint = `${environment.api_user}refreshtoken?channel_id=2&refresh_token=${refreshToken}`;
    return this.http.post(
      this.baseURL + endpoint,
      {},
      {
        headers: this.getHeadersWithToken(noAuthorization),
      }
    );
  }

  /**
   *
   * @param accessToken
   * @returns the users profile
   */
  getProfile(accessToken: string) {
    const endpoint = `/api/v1/user/refreshtoken${accessToken}`;
    return this.http.get(this.baseURL + endpoint, {
      headers: this.getHeadersWithToken(),
    });
  }

  /**
   *
   * @param authToken
   * @returns the result of logout operation
   */
  //http://localhost:8000/api/v1/user/logout?channel_id=2&remember_token=a
  logout(remember_token: string) {
    const body = {
      channel_id: 2,
      remember_token: remember_token,
    };
    const endpoint = `${environment.api_user}logout?channel_id=2&remember_token=${remember_token}`;
    return this.http.post(
      this.baseURL + endpoint,
      {},
      {
        headers: this.getHeadersWithToken(),
      }
    );
  }

  /**
   * checks if the token is expired
   * @returns the token expiration status
   */
  checkAuthTokenExpiration() {
    const date = new Date();
    const storedAuthTokenTime = localStorage.getItem('authToken_time');

    // when is greater than 5 min in ms
    if (
      storedAuthTokenTime &&
      date.getTime() - parseInt(storedAuthTokenTime) > 300000
    ) {
      return true;
    }
    return false;
  }
}
