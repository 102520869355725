import { Component, OnInit, OnDestroy } from '@angular/core';
import { NoticiasComponent } from '../noticias/noticias.component';
import { RetosComponent } from '../retos/retos.component';
import { HeaderComponent } from '@components/header/header.component';
import { ActivatedRoute, RouterLink, Router } from '@angular/router';
import { TranslateService } from '@services/translate/translate.service';
import { Subscription, catchError, throwError } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NoticiasService } from '@services/noticias/noticias.service';
import { DataShareService } from '@services/data-share/data-share.service';
import { AngularMaterialModule } from 'app/module/material/material-module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import moment from 'moment';
import 'moment/locale/es';

@Component({
  selector: 'app-noticia-details',
  standalone: true,
  imports: [
    NoticiasComponent,
    RetosComponent,
    HeaderComponent,
    RouterLink,
    AngularMaterialModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  templateUrl: './noticia-details.component.html',
  styleUrls: ['./noticia-details.component.scss'],
})
export class NoticiaDetailsComponent implements OnInit, OnDestroy {
  noticiaId: string | null = null;
  date = '';
  title = '';
  foto = '';
  description = '';
  isLoading = true;
  private noticiaSubscription!: Subscription;
  noticia: any;

  constructor(
    public translateService: TranslateService,
    private activatedRoute: ActivatedRoute,
    private dataShareService: DataShareService,
    private noticiasService: NoticiasService,
    private router: Router,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    try {
      this.activatedRoute.params
        .pipe(
          catchError((err: any) => {
            console.log('Error occurred', err);
            return throwError(err);
          })
        )
        .subscribe(
          (params) => {
            try {
              this.noticiaId = params['id'];
              console.log('ID de noticia:', this.noticiaId);

              if (!this.noticiaId || this.noticiaId.trim() === '') {
                console.error('El ID de la noticia es nulo o vacío.');
                this.snackBar.open(
                  this.translateService.translate('SNACKBARS.NEWID_NOT_VALID'),
                  this.translateService.translate('MAIN.BUTTONS.CLOSE_BUTTON'),
                  {
                    duration: 3000,
                  }
                );
                this.router.navigate(['/inicio']);
                return;
              }

              const storedNoticia = localStorage.getItem('storeNoticiaActiva');
              if (storedNoticia) {
                const parsedNoticia = JSON.parse(storedNoticia);
                if (parsedNoticia && parsedNoticia.id === this.noticiaId) {
                  this.noticia = parsedNoticia;
                  this.setNoticiaDetails(this.noticia);
                } else {
                  this.getNoticiaDetails();
                }
              } else {
                this.getNoticiaDetails();
              }
            } catch (error) {
              console.error('Error procesando los parámetros de la ruta:', error);
            }
          },
          (err) => console.log(' Error ', err),
          () => console.log('Request completed')
        );
    } catch (error) {
      console.error('Error en ngOnInit:', error);
    }
  }

  ngOnDestroy() {
    try {
      if (this.noticiaSubscription) {
        this.noticiaSubscription.unsubscribe();
      }
    } catch (error) {
      console.error('Error en ngOnDestroy:', error);
    }
  }

  getNoticiaDetails() {
    try {
      this.isLoading = true;
      if (this.noticiaId) {
        this.noticiaSubscription = this.noticiasService
          .getNoticiaById(this.noticiaId)
          .pipe(
            catchError((err: any) => {
              console.log('Error occurred', err);
              return throwError(err);
            })
          )
          .subscribe(
            (noticia) => {
              try {
                if (noticia) {
                  console.log('Noticia obtenida desde la API:', noticia);
                  this.noticia = noticia;
                  this.setNoticiaDetails(noticia);
                  localStorage.setItem('storeNoticiaActiva', JSON.stringify(noticia));
                } else {
                  this.handleNotFoundError();
                }
              } catch (error) {
                console.error('Error al procesar la noticia:', error);
              } finally {
                this.isLoading = false;
              }
            },
            (error) => {
              console.error('Error al obtener la noticia desde la API:', error);
              this.handleNotFoundError();
              this.isLoading = false;
            }
          );
      }
    } catch (error) {
      console.error('Error en getNoticiaDetails:', error);
      this.isLoading = false;
    }
  }

  handleNotFoundError() {
    try {
      this.snackBar.open(
        this.translateService.translate('MAIN.ERRORS.NEW_NOT_FOUND'),
        this.translateService.translate('MAIN.BUTTONS.CLOSE_BUTTON'),
        {
          duration: 3000,
        }
      );
      this.router.navigate(['/inicio']);
    } catch (error) {
      console.error('Error al manejar la noticia no encontrada:', error);
    }
  }

  setNoticiaDetails(noticia: any) {
    try {
      const lang = noticia.lang || {};
      this.date = noticia.date_start || 'No date available';
      if (noticia.date_start) {
        moment.locale(this.translateService.getCurrentLanguage().toLowerCase());
        this.date = moment(noticia.date_start).format('LL');
      }
      this.title = lang.name || 'No title available';
      this.foto = lang.file?.main[0] || 'https://github.com/sYlskY7.png';
      this.description = lang.description || 'No description available';
    } catch (error) {
      console.error('Error al establecer los detalles de la noticia:', error);
    }
    this.isLoading = false;
  }

  isMainpage(): boolean {
    try {
      return this.router.url !== '/inicio';
    } catch (error) {
      console.error('Error al verificar la página principal:', error);
      return false;
    }
  }
}