import { Component, OnInit, OnDestroy } from '@angular/core';
import { WikiService } from '@services/wiki/wiki.service';
import { HeaderComponent } from '@components/header/header.component';
import { TranslateService } from '@services/translate/translate.service';
import { AngularMaterialModule } from 'app/module/material/material-module';
import { Subscription, catchError, throwError } from 'rxjs';
import { DataShareService } from '@services/data-share/data-share.service';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'wiki',
  standalone: true,
  imports: [AngularMaterialModule, HeaderComponent, RouterLink],
  templateUrl: './wiki.component.html',
  styleUrls: ['./wiki.component.scss'],
})
export class WikiComponent implements OnInit, OnDestroy {
  wikiItems: any[] = [];
  selectedSection: any;
  isLoading = true;
  languageSubscription: Subscription | undefined;
  selectedWikiSectionIndexSubscription: Subscription | undefined;

  constructor(
    private wikiService: WikiService,
    public translateService: TranslateService,
    private dataShareService: DataShareService
  ) {}

  ngOnInit(): void {
    try {
      this.languageSubscription = this.translateService.language$
        .pipe(
          catchError((err: any) => {
            console.log('Error occurred', err);
            return throwError(err);
          })
        )
        .subscribe(() => {
          this.loadWikiItems();
        });

      // Suscripción a la selección de la sección de la Wiki
      this.selectedWikiSectionIndexSubscription = this.dataShareService.selectedWikiSectionIndex$
        .pipe(
          catchError((err: any) => {
            console.log('Error occurred in selectedWikiSectionIndex', err);
            return throwError(err);
          })
        )
        .subscribe((index) => {
          try {
            console.log('Received Wiki section index:', index);
            if (index !== null && this.wikiItems.length > index) {
              this.selectSectionByIndex(index);
              this.dataShareService.clearSelectedWikiSectionIndex();
            }
          } catch (error) {
            console.error('Error handling wiki section index:', error);
          }
        });
    } catch (error) {
      console.error('Error during ngOnInit:', error);
    }
  }

  ngOnDestroy(): void {
    try {
      if (this.languageSubscription) {
        this.languageSubscription.unsubscribe();
      }
      if (this.selectedWikiSectionIndexSubscription) {
        this.selectedWikiSectionIndexSubscription.unsubscribe();
      }
    } catch (error) {
      console.error('Error during ngOnDestroy:', error);
    }
  }

  loadWikiItems(): void {
    try {
      this.isLoading = true;
      this.wikiService
        .getWikiItems()
        .pipe(
          catchError((err: any) => {
            console.log('Error occurred', err);
            return throwError(err);
          })
        )
        .subscribe(
          (items) => {
            try {
              this.wikiItems = items;
              this.selectedSection = items[0];
              this.isLoading = false;

              // Reaplicar el índice almacenado después de que los items se carguen
              const currentSelectedIndex = this.dataShareService.getSelectedWikiSectionIndex();
              if (currentSelectedIndex !== null && items.length > currentSelectedIndex) {
                this.selectSectionByIndex(currentSelectedIndex);
              }
            } catch (error) {
              console.error('Error processing wiki items:', error);
            }
          },
          (error) => {
            console.error('Error loading wiki items:', error);
            this.isLoading = false;
          }
        );
    } catch (error) {
      console.error('Error in loadWikiItems:', error);
    }
  }

  selectSectionByIndex(index: number): void {
    try {
      if (this.wikiItems && this.wikiItems.length > index) {
        this.selectedSection = this.wikiItems[index];
      } else {
        console.warn('Wiki section index out of bounds');
      }
    } catch (error) {
      console.error('Error in selectSectionByIndex:', error);
    }
  }

  selectSection(section: any): void {
    try {
      this.selectedSection = section;
    } catch (error) {
      console.error('Error in selectSection:', error);
    }
  }
}