import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '@services/auth/auth.service';
import { TranslateService } from '@services/translate/translate.service';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RetosService {
  private baseURL: string;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private translateService: TranslateService
  ) {
    if (environment.local == true) {
      this.baseURL = `${environment.localUrl}:${environment.localPort}`;
    } else {
      // when is deployed
      this.baseURL = `${environment.url}`;
    }
  }

  // Obtener todas las acciones
  getActions(page: number = 0, limit: number = 10): Observable<any[]> {
    const language = this.translateService.getCurrentLanguage(); // Usar el método público
    const url = `${this.baseURL}${environment.api_actions}?channel_id=2&lang=${language}&page=${page}&limit=${limit}`;
    console.log('Request URL:', url);

    return this.http
      .get<any>(url, { headers: this.authService.getHeadersWithToken() })
      .pipe(
        map((response) => {
          // Mapea sobre todos los elementos en response.data y recoge todas las acciones
          const allActions = response.data.flatMap((entry: any) =>
            entry.action
              // Filtro: solo incluir acciones que no sean favoritas ni completadas
              .filter((action: any) => !action.favourite && !action.completed)
              .map((action: any) => ({
                ...action,
                lang: action.lang[0][language.toUpperCase()],
              }))
          );
          return allActions;
        }),
        catchError((error) => {
          console.error('Error al obtener acciones:', error);
          return throwError(error); // Manejo de errores
        })
      );
  }

  // Obtener una acción específica por ID
  getActionById(actionId: string): Observable<any> {
    const language = this.translateService.getCurrentLanguage(); // Usar el idioma actual
    const url = `${this.baseURL}${environment.api_actions}?channel_id=2&lang=${language}&id=${actionId}`;
    console.log('Request URL (getActionById):', url);

    return this.http
      .get<any>(url, { headers: this.authService.getHeadersWithToken() })
      .pipe(
        map((response) => {
          const action = response.data[0]?.action[0]; // Obtener la primera acción de la respuesta
          if (action && action.lang) {
            // Procesar el idioma de la acción
            const langData =
              action.lang.find((l: any) => l[language.toUpperCase()]) ||
              action.lang[0];
            action.lang = langData
              ? langData[language.toUpperCase()] || Object.values(langData)[0]
              : null;
          }
          return action;
        }),
        catchError((error) => {
          console.error('Error al obtener la acción por ID:', error);
          return throwError(error); // Manejo de errores
        })
      );
  }
}
