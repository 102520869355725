import { Component, OnInit } from '@angular/core';
import {
  FormControl,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { HeaderComponent } from '@components/header/header.component';
import { RetosComponent } from '@components/main/retos/retos.component';
import { TranslateService } from '@services/translate/translate.service';
import { AppComponent } from 'app/app.component';
import { AngularMaterialModule } from 'app/module/material/material-module';
import { UserProfileService } from '@services/user-profile/user-profile.service';
import { ContactFormService } from '@services/contact-form/contact-form.service';
import { DataShareService } from '@services/data-share/data-share.service'; // Importar el servicio compartido
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Component({
  selector: 'contact-form',
  standalone: true,
  imports: [
    HeaderComponent,
    AngularMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    RouterLink,
    RetosComponent,
    AppComponent,
  ],
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss'],
})
export class ContactFormComponent implements OnInit {
  fileToUpload: any;
  validForm = false;
  formType = '';
  display: FormControl = new FormControl('', Validators.required);
  acceptFormats = 'image/png, image/jpeg, image/gif, image/jpg, image/svg';
  actions: { id: number; name: string }[] = [];
  isLoading = true;
  noActionsMessage = 'No tienes ninguna acción en curso...';
  selectedAction: any = '';
  message: string = '';
  autoCompleteActionName: string | null = null; // Agregar esta propiedad
  comments:string = '';
  fileName = '';

  constructor(
    private router: Router,
    public translateService: TranslateService,
    private activatedRoute: ActivatedRoute,
    private userProfileService: UserProfileService,
    private contactFormService: ContactFormService,
    private dataShareService: DataShareService // Inyectar el servicio compartido
  ) {}

  ngOnInit() {
    // Obtener los parámetros de la URL
    this.activatedRoute.params
      .pipe(
        catchError((err: any) => {
          console.log('Error occurred', err);
          return throwError(err);
        })
      )
      .subscribe(
        (params) => {
          this.formType = params['formType'];
          console.log('Form type:', this.formType);
          this.fileToUpload = null;
          this.selectedAction = '';
          this.comments = '';
        },
        (err) => console.log(' Error while getting the Social User', err),
        () => console.log('Social User request completed')
      );

    // Suscribirse al BehaviorSubject para obtener el nombre del desafío activo
    this.dataShareService.reto$.subscribe(
      (reto) => {
        if (reto && reto.lang && reto.lang.name) {
          this.autoCompleteActionName = reto.lang.name;
          console.log(
            'Auto-completing action name:',
            this.autoCompleteActionName
          );
        }
      },
      (err) => console.log(' Error ', err),
      () => console.log('Request completed')
    );

    this.loadUserFavourites();
  }

  loadUserFavourites() {
    this.userProfileService
      .getUserFavourites()
      .pipe(
        catchError((err: any) => {
          console.log('Error occurred', err);
          return throwError(err);
        })
      )
      .subscribe(
        (response: any) => {
          console.log('Full API response:', response);
          this.actions = [];

          if (response && response.length > 0) {
            response.forEach((action: any) => {
              const langData = action.lang || {};
              const actionName = langData.name || 'No Title';
              const actionId = action.id;
              this.actions.push({ id: actionId, name: actionName });
              console.log(`Action loaded: ${actionName} with ID: ${actionId}`);

              // Autocompletar la acción seleccionada si coincide con el nombre del reto activo
              if (
                this.autoCompleteActionName &&
                actionName === this.autoCompleteActionName
              ) {
                this.selectedAction = { id: actionId, name: actionName };
                console.log('Autocompleted action:', this.selectedAction);
              }
            });
          } else {
            console.warn('No hay datos válidos disponibles en la respuesta.');
            this.actions.push({ id: 0, name: this.noActionsMessage });
          }

          if (this.actions.length === 0) {
            console.warn('No hay acciones favoritas disponibles.');
            this.actions.push({ id: 0, name: this.noActionsMessage });
          } else {
            console.log('Acciones favoritas mostradas:', this.actions);
          }

          this.isLoading = false;
        },
        (error) => {
          console.error('Error al obtener las acciones favoritas:', error);
          this.actions.push({ id: 0, name: this.noActionsMessage });
          this.isLoading = false;
        },
        () => console.log('Request completed')
      );
  }

  checkFile(files: any): void {
    if (files.length) {
      this.fileToUpload = files[0];
      //this.validForm = true;
      this.fileName = this.fileToUpload.name;
      if (this.fileToUpload) {
        this.display.patchValue(`${this.fileToUpload.name}`);
      }

      const reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = (event: any) => {};
    } else {
      this.display.patchValue('');
      this.validForm = false;
    }
  }

  selectAction(action: { id: number; name: string }) {
    console.log('Acción seleccionada:', action);
    this.selectedAction = action;
    this.validateFormdata();
  }

  saveChanges() {
    if (this.formType === 'puntos') {
      // Lógica para enviar datos a la API de "solicitar puntos"
      if (
        !this.selectedAction ||
        this.selectedAction.name === this.noActionsMessage
      ) {
        console.warn('No hay ninguna acción válida seleccionada.');
        return;
      }

      const action_id = this.selectedAction.id;

      this.contactFormService
        .sendPointsRequest(action_id, this.fileToUpload, this.message)
        .pipe(
          catchError((err: any) => {
            console.log('Error occurred', err);
            return throwError(err);
          })
        )
        .subscribe(
          (response) => {
            console.log(
              'Formulario de solicitud de puntos enviado con éxito:',
              response
            );
          },
          (error) => {
            console.error(
              'Error al enviar el formulario de solicitud de puntos:',
              error
            );
          }
        );
    } else if (this.formType === 'soporte') {
      // Lógica para enviar datos a la API de "contactar con la gamba"
      this.contactFormService
        .sendSupportRequest(this.message, this.fileToUpload)
        .pipe(
          catchError((err: any) => {
            console.log('Error occurred', err);
            return throwError(err);
          })
        )
        .subscribe(
          (response) => {
            console.log('Formulario de soporte enviado con éxito:', response);
          },
          (error) => {
            console.error('Error al enviar el formulario de soporte:', error);
          }
        );
    }
  }

  isMainpage(): boolean {
    return this.router.url !== '/inicio';
  }

  validateFormdata() {
    this.validForm = false;
    if (this.formType === 'puntos' && this.selectedAction && this.comments.trim().length > 0){
      this.validForm = true;
    } else if (this.formType === 'soporte' && this.comments.trim().length > 0){
      this.validForm = true;
    }
  }

  compareActions(
    c1: { id: number; name: string },
    c2: { id: number; name: string }
  ): boolean {
    return c1 && c2 ? c1.id === c2.id : c1 === c2;
  }
}
