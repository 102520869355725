import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '@services/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class ActionsService {
  baseURL: string;

  constructor(
    private authService: AuthService,    
    private http: HttpClient
  ) {
    // Forzar el uso de HTTPS si no está en local
    if (environment.local === true) {
      this.baseURL = `${environment.localUrl}:${environment.localPort}`;
    } else {
      // Forzar siempre HTTPS
      this.baseURL = environment.url.replace('http://', 'https://');
    }
  }

  // Método para añadir a favoritos
  addFavorite(data: any) {
    const headers = this.authService.getHeadersWithToken();
    const body = {
      channel_id: 2,
      action_id: data.action_id,
    };
    const endpoint = `${environment.api_actions_addfavourite}`;
    const httpsUrl = this.baseURL + endpoint; // URL base que asegura HTTPS

    return this.http.post(httpsUrl, body, {
      headers: headers,
    });
  }

  // Método para suscribirse a eventos
  subscribeToEvent(data: any) {
    const headers = this.authService.getHeadersWithToken();
    const body = {
      channel_id: 2,
      socialToken: data.socialToken,
    };
    const endpoint = `${environment.api_actions}/subscribe`;
    return this.http.post(this.baseURL + endpoint, body, {
      headers: headers,
    });
  }
}
