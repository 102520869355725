import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AngularMaterialModule } from 'app/module/material/material-module';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@services/translate/translate.service';
import { Router } from '@angular/router'; // Importa Router
import { catchError, throwError } from 'rxjs';

interface Language {
  value: string;
  viewValue: string;
  flag?: string;
  defaultLanguage: number;
}

@Component({
  selector: 'app-config-dialog',
  templateUrl: './config-dialog.component.html',
  styleUrls: ['./config-dialog.component.scss'],
  imports: [AngularMaterialModule],
  standalone: true,
})
export class ConfigDialogComponent implements OnInit {
  selectedLanguage?: string;
  languages: Language[] = [];
  currentLanguage: any;

  constructor(
    public dialogRef: MatDialogRef<ConfigDialogComponent>,
    private _snackBar: MatSnackBar,
    public translateService: TranslateService,
    private router: Router // Inyecta Router
  ) {}

  ngOnInit(): void {
    this.fetchLanguages();
  
    // Llamada a getCurrentLanguage para obtener el idioma actual
    const selectedLanguage = this.selectedLanguage
      ? this.selectedLanguage
      : this.translateService.getCurrentLanguage(); 
  
    this.currentLanguage = selectedLanguage;
  }

  fetchLanguages(): void {
    this.translateService
      .getCompanyLanguages()
      .pipe(
        catchError((err: any) => {
          console.log('Error occurred', err);
          return throwError(err);
        })
      )
      .subscribe(
        (response: any) => {
          // Assuming the response structure matches with the expected one
          const languagesData = response.data[0].language;
          this.languages = languagesData.map((language: any) => ({
            value: language.abbreviation.toLowerCase(),
            viewValue: language.abbreviation,
            flag: language.flag,
            defaultLanguage: language.default,
          }));
        },      
        (err) => console.log(' Error ', err),
        () => console.log('Request completed')
      );
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }

  processLanguage(event: any) {
    this.selectedLanguage = event.value;
    this.currentLanguage = this.selectedLanguage;
  }

  onSaveClick(): void {
    if (this.selectedLanguage) {
      this.translateService
        .use(this.selectedLanguage)
        .pipe(
          catchError((err: any) => {
            console.log('Error occurred', err);
            return throwError(err);
          })
        )
        .subscribe(
          () => {
            this._snackBar.open(
              this.translateService.translate(
                'SNACKBARS.LANGUAGE_MODIFIED_SUCCESFULLY'
              ),
              undefined,
              {
                duration: 6000,
                verticalPosition: 'top',
              }
            );
            this.dialogRef.close();
            this.router.navigate(['/inicio']);
          },
          (err) => console.log(' Error ', err),
          () => console.log('Request completed')
        );
    } else {
      this.dialogRef.close();
    }
  }
}
