import { Component, OnInit } from '@angular/core';
import { Router, RouterModule, RouterOutlet } from '@angular/router';
import { TranslateService } from '@services/translate/translate.service';
import { AngularMaterialModule } from 'app/module/material/material-module';
import { ConfigDialogComponent } from '../dialogs/config-dialog/config-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '@services/auth/auth.service';
import { SocialAuthService } from '@abacritt/angularx-social-login';
import { AppComponent } from 'app/app.component';
import { UserProfileService } from '@services/user-profile/user-profile.service'; // Servicio para obtener el perfil del usuario
import { Subscription, catchError, throwError } from 'rxjs';
import { DataShareService } from '@services/data-share/data-share.service';

@Component({
  selector: 'header-menu',
  standalone: true,
  imports: [AngularMaterialModule, RouterOutlet, RouterModule, AppComponent],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent implements OnInit {
  socialUser: any = {};
  localUser: any = {};
  loggedUserName = true;
  userVirtualAmount: number | null = null; // Monedas virtuales del usuario
  appName = require('../../../../package.json').name;

  constructor(
    private dataShare: DataShareService,
    private oAuthService: SocialAuthService,
    private authService: AuthService,
    private router: Router,
    private appComponent: AppComponent,
    public translateService: TranslateService,
    public dialog: MatDialog,
    private userProfileService: UserProfileService // Inyectar el servicio para obtener el perfil del usuario
  ) {}

  ngOnInit() {
    try {
      const socialUser = localStorage.getItem('socialUser');
      const localUser = localStorage.getItem('localUser');
      if (socialUser) {
        this.socialUser = JSON.parse(socialUser);
      }
      if (localUser) {
        this.localUser = JSON.parse(localUser);
      }

      // Llamada al método para obtener las monedas virtuales
      this.getUserVirtualAmount();
    } catch (error) {
      console.error('Error en ngOnInit:', error);
    }
  }

  isNotMainPage(): boolean {
    try {
      return this.router.url !== '/inicio';
    } catch (error) {
      console.error('Error al verificar si no está en la página principal:', error);
      return true; // Fallback en caso de error
    }
  }

  translate(key: string): string {
    try {
      return this.translateService.translate(key);
    } catch (error) {
      console.error('Error en translate:', error);
      return key; // Devuelve la clave en caso de error
    }
  }

  // Método para obtener las monedas virtuales del usuario
  getUserVirtualAmount(): void {
    try {
      this.userProfileService
        .getUserProfile()
        .pipe(
          catchError((err: any) => {
            console.log('Error occurred', err);
            return throwError(err);
          })
        )
        .subscribe(
          (response) => {
            try {
              if (response?.data?.length > 0 && response.data[0].user?.length > 0) {
                const user = response.data[0].user[0];
                this.userVirtualAmount = user.virtual_amount; // Asignar las monedas virtuales
              }
            } catch (error) {
              console.error('Error procesando la respuesta del perfil de usuario:', error);
            }
          },
          (error) => {
            console.error('Error al obtener las monedas virtuales:', error);
          }
        );
    } catch (error) {
      console.error('Error en getUserVirtualAmount:', error);
    }
  }

  logout() {
    try {
      const loggedInUser = localStorage.getItem('loggedInUser') || null;
      if (loggedInUser) {
        const remember_token = localStorage.getItem('remember_token');
        if (remember_token) {
          this.appComponent.performLogout(remember_token);
        } else {
          this.appComponent.localLogout();
        }
      }

      this.authService.isAuth.next(false);
      localStorage.clear();
      this.router.navigateByUrl('/login');
      this.oAuthService.signOut().then(
        (data) => {
          console.log('signed out from google');
        },
        (error) => {
          console.log('error signing out from google: ' + error.message);
        }
      );
      window.location.reload();
    } catch (error) {
      console.error('Error during logout:', error);
    }
  }

  openConfig(): void {
    try {
      const dialogRef = this.dialog.open(ConfigDialogComponent, {
        width: '60%',
      });

      dialogRef.afterClosed().subscribe((result) => {
        // MANEJAR ACCIONES TRAS CERRAR EL CONFIGDIALOG
      });
    } catch (error) {
      console.error('Error al abrir la configuración:', error);
    }
  }

  handleHome() {
    try {
      if (location.href.includes('/inicio')) {
        // updates the show main state
        this.dataShare.setUpdateShowMain(true);
      }
    } catch (error) {
      console.error('Error en handleHome:', error);
    }
  }
}