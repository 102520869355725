import { Component, OnInit } from '@angular/core';
import { HeaderComponent } from '@components/header/header.component';
import { RetosComponent } from '../retos/retos.component';
import { AngularMaterialModule } from 'app/module/material/material-module';
import { RouterLink, Router } from '@angular/router';
import { TranslateService } from '@services/translate/translate.service';
import { UserProfileService } from '@services/user-profile/user-profile.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormsModule } from '@angular/forms';
import { catchError, throwError } from 'rxjs';

@Component({
  selector: 'app-user-profile',
  standalone: true,
  imports: [
    HeaderComponent,
    RetosComponent,
    AngularMaterialModule,
    FormsModule,
    RouterLink,
  ],
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
})
export class UserProfileComponent implements OnInit {
  dataSource: any = [];
  showItems: any[] = [];
  completedActions: any[] = [];
  favouriteActions: any[] = [];
  formType = '';
  isLoading = true;
  userName: string = '';
  userPoints: number | null = null;
  userVirtualAmount: number | null = null;
  userEmail: string = '';
  userProfileImage: string = '';
  userCompany: string = '';
  userCompanyDescription: string = '';
  userCompanyLogo: string = '';
  socialUser: any = {};
  localUser: any = {};
  fileToUpload: File | null = null;
  userLevelImage: string = '';

  displayedColumns: string[] = ['accion', 'fecha', 'puntos', 'gambacoins'];

  constructor(
    private userProfileService: UserProfileService,
    public translateService: TranslateService,
    private router: Router,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    try {
      this.loadUserProfile();
      this.loadUserTransactions();
      this.loadUserFavourites();
      this.loadUserCompleted();

      const socialUser = localStorage.getItem('socialUser');
      if (socialUser) {
        this.socialUser = JSON.parse(socialUser);
      }
    } catch (error) {
      console.error('Error during ngOnInit:', error);
    }
  }

  isMainpage(): boolean {
    return this.router.url !== '/inicio';
  }

  loadUserProfile() {
    try {
      this.userProfileService
        .getUserProfile()
        .pipe(
          catchError((err: any) => {
            console.log('Error occurred', err);
            return throwError(err);
          })
        )
        .subscribe(
          (response) => {
            if (response?.data?.length > 0 && response.data[0].user?.length > 0) {
              const user = response.data[0].user[0];
              this.userName = user.name;
              this.userPoints = user.points;
              this.userVirtualAmount = user.virtual_amount;
              this.userEmail = user.email;

              // // Intentar cargar la imagen de perfil
              // try {
              //   this.userProfileImage = user.file?.image[0]?.url || this.socialUser?.photoUrl || 'https://e7.pngegg.com/pngimages/122/453/png-clipart-computer-icons-user-profile-avatar-female-profile-heroes-head-thumbnail.png';
              // } catch (error) {
              //   console.error('Error al cargar la imagen de perfil:', error);
              //   this.userProfileImage = this.socialUser?.photoUrl || 'https://e7.pngegg.com/pngimages/122/453/png-clipart-computer-icons-user-profile-avatar-female-profile-heroes-head-thumbnail.png';
              // }

              this.userProfileImage = this.socialUser?.photoUrl;

              // Intentar cargar la imagen del nivel del usuario
              try {
                if (user.level?.file?.image[0]?.url) {
                  this.userLevelImage = user.level.file.image[0].url;
                } else {
                  this.userLevelImage = 'https://t3.ftcdn.net/jpg/01/21/64/88/360_F_121648819_ZQ0tZ6tjLzxim1SG7CQ86raBw4sglCzB.jpg';
                }
              } catch (error) {
                console.error('Error al cargar la imagen del nivel:', error);
                this.userLevelImage = 'https://t3.ftcdn.net/jpg/01/21/64/88/360_F_121648819_ZQ0tZ6tjLzxim1SG7CQ86raBw4sglCzB.jpg';
              }

              // Cargar datos de la compañía
              try {
                this.userCompany = user.company?.name || '';
                this.userCompanyDescription = user.company?.description || '';
                this.userCompanyLogo = user.company?.file?.image[0]?.url || '';
              } catch (error) {
                console.error('Error al cargar la información de la compañía:', error);
              }
            }
            this.isLoading = false;
          },
          (error) => {
            console.error('Error al cargar el perfil de usuario:', error);
            this.isLoading = false;
          }
        );
    } catch (error) {
      console.error('Error during loadUserProfile:', error);
    }
  }

  // Método público para mostrar las monedas del usuario
  getUserVirtualAmount(): number | null {
    return this.userVirtualAmount;
  }

  triggerFileUpload() {
    try {
      const fileInput = document.getElementById('fileInput') as HTMLInputElement;
      fileInput.click();
    } catch (error) {
      console.error('Error during triggerFileUpload:', error);
    }
  }

  onFileSelected(event: Event) {
    try {
      const input = event.target as HTMLInputElement;
      if (input.files && input.files.length > 0) {
        this.fileToUpload = input.files[0];

        const validImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];
        if (!validImageTypes.includes(this.fileToUpload.type)) {
          this.snackBar.open(
            this.translateService.translate('SNACKBARS.SELECT_VALID_IMG'),
            this.translateService.translate('MAIN.BUTTONS.CLOSE_BUTTON'),
            {
              duration: 3000,
              horizontalPosition: 'center',
              verticalPosition: 'top',
            }
          );
          return;
        }

        const maxSizeInMB = 2;
        const maxSizeInBytes = maxSizeInMB * 1024 * 1024;
        if (this.fileToUpload.size > maxSizeInBytes) {
          this.snackBar.open(
            `${this.translateService.translate('SNACKBARS.FILE_OVERSIZE')} ${maxSizeInMB}MB.`,
            this.translateService.translate('MAIN.BUTTONS.CLOSE_BUTTON'),
            {
              duration: 3000,
              horizontalPosition: 'center',
              verticalPosition: 'top',
            }
          );
          return;
        }

        this.uploadProfileImage();
      }
    } catch (error) {
      console.error('Error during onFileSelected:', error);
    }
  }

  uploadProfileImage() {
    try {
      if (this.fileToUpload) {
        this.userProfileService
          .uploadProfileImage(this.fileToUpload)
          .pipe(
            catchError((err: any) => {
              console.log('Error occurred', err);
              return throwError(err);
            })
          )
          .subscribe(
            (response) => {
              console.log('Imagen subida con éxito:', response);
              if (response && response.imageUrl) {
                this.userProfileImage = response.imageUrl;
              }
            },
            (error) => {
              console.error('Error al subir la imagen:', error);
              this.userProfileImage = this.socialUser?.photoUrl || this.userProfileImage;
            }
          );
      }
    } catch (error) {
      console.error('Error during uploadProfileImage:', error);
    }
  }

  loadUserTransactions() {
    try {
      this.userProfileService
        .getUserTransactions()
        .pipe(
          catchError((err: any) => {
            console.log('Error occurred', err);
            return throwError(err);
          })
        )
        .subscribe(
          (response) => {
            if (response?.data?.length > 0) {
              this.dataSource = response.data[0].transaction.map(
                (trans: any) => ({
                  accion: trans.description,
                  fecha: new Date(trans.date),
                  puntos: trans.points,
                  gambacoins: trans.virtual_amount,
                })
              );
            }
            this.isLoading = false;
          },
          (error) => {
            console.error('Error al cargar las transacciones del usuario:', error);
            this.isLoading = false;
          }
        );
    } catch (error) {
      console.error('Error during loadUserTransactions:', error);
    }
  }

  loadUserFavourites() {
    try {
      this.userProfileService
        .getUserFavourites()
        .pipe(
          catchError((err: any) => {
            console.log('Error occurred', err);
            return throwError(err);
          })
        )
        .subscribe(
          (response: any) => {
            this.favouriteActions = [];
            if (response && response.length > 0) {
              response.forEach((action: any) => {
                const langData = action.lang || {};
                this.favouriteActions.push({
                  id: action.id,
                  title: langData.name || 'No Title',
                  description: langData.short_description || 'No Description',
                  imageUrl: langData.file?.main[0]?.main[0]?.url || 'https://github.com/sYlskY7.png',
                  points: action.points,
                  date_start: action.date_start,
                  date_end: action.date_end,
                });
              });
            }
            this.isLoading = false;
          },
          (error) => {
            console.error('Error al obtener los retos:', error);
            this.isLoading = false;
          }
        );
    } catch (error) {
      console.error('Error during loadUserFavourites:', error);
    }
  }

  loadUserCompleted() {
    try {
      this.userProfileService
        .getUserCompleted()
        .pipe(
          catchError((err: any) => {
            console.log('Error occurred', err);
            return throwError(err);
          })
        )
        .subscribe(
          (response: any) => {
            this.completedActions = [];
            if (response && response.length > 0) {
              response.forEach((action: any) => {
                const langData = action.lang || {};
                this.completedActions.push({
                  id: action.id,
                  title: langData.name || 'No Title',
                  description: langData.short_description || 'No Description',
                  imageUrl: langData.file?.main[0]?.main[0]?.url || 'https://github.com/sYlskY7.png',
                  points: action.points,
                  date_start: action.date_start,
                  date_end: action.date_end,
                });
              });
            }
            this.isLoading = false;
          },
          (error) => {
            console.error('Error al obtener los retos:', error);
            this.isLoading = false;
          }
        );
    } catch (error) {
      console.error('Error during loadUserCompleted:', error);
    }
  }
}