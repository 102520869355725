import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable,  map, catchError, throwError } from 'rxjs';
import { environment } from 'environments/environment';
import { AuthService } from '../auth/auth.service';
import { TranslateService } from '@services/translate/translate.service';

@Injectable({
  providedIn: 'root',
})
export class UserProfileService {
  baseURL: string;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private translateService: TranslateService
  ) {
    if (environment.local == true) {
      this.baseURL = `${environment.localUrl}:${environment.localPort}`;
    } else {
      // cuando esté desplegado
      this.baseURL = `${environment.url}`;
    }
  }

  // Método para obtener usuarios desde el endpoint real
  getUserProfile(): Observable<any> {
    const url = `${this.baseURL}${environment.api_userProfile}?channel_id=2`;
    console.log('Request URL:', url);
    return this.http.get<any>(url, {
      headers: this.authService.getHeadersWithToken(),
    });
  }
  // Método para cargar una foto en el perfil del usuario
  uploadProfileImage(file: File): Observable<any> {
    const url = `${this.baseURL}${environment.api_uploadProfilePic}?channel_id=2&private=1`;
  
    // Leer el archivo como base64 
    const reader = new FileReader();
  
    return new Observable((observer) => {
      reader.onload = () => {
        // Archivo a Base64 para mandarlo como JSON
        const base64File = reader.result?.toString();
        
        // Asegurarse de que se convierte en base64
        if (base64File) {
          const body = {
            files: [base64File],  
            private: 1,
            gallery: 0  
          };
  
          const headers = this.authService.getHeadersWithToken();
  
          // Realizar la solicitud POST
          this.http.post<any>(url, body, { headers })
            .subscribe(
              (response) => {
                console.log('Imagen subida con éxito:', response);
                observer.next(response);
                observer.complete();
              },
              (error) => {
                console.error('Error al subir la imagen:', error);
                observer.error(error);
              }
            );
        } else {
          observer.error(new Error('No se pudo convertir el archivo a Base64'));
        }
      };
  
      reader.onerror = (error) => observer.error(error);
  
      // Leer el archivo como Data URL (Base64)
      reader.readAsDataURL(file);
    });
  }
  // Método para obtener las transacciones de un usuario

  getUserTransactions(): Observable<any> {
    const url = `${this.baseURL}${environment.api_user_transactions}?channel_id=2`;
    console.log('Request URL:', url);
    return this.http.get<any>(url, {
      headers: this.authService.getHeadersWithToken(),
    });
  }

  // Método para obtener las acciones en curso de un usuario
  getUserFavourites(): Observable<any[]> {
    const language = this.translateService.getCurrentLanguage();
    const url = `${this.baseURL}${environment.api_actions}?channel_id=2&favourite=1`;
    console.log('Request fav actions URL:', url);

    return this.http
      .get<any>(url, { headers: this.authService.getHeadersWithToken() })
      .pipe(
        map((response) => {
          // Mapea sobre todos los elementos en response.data y recoge todas las acciones favoritas
          const allFavourites = response.data.flatMap((entry: any) =>
            entry.action.map((action: any) => ({
              ...action,
              lang: action.lang[0][language.toUpperCase()],
            }))
          );

          return allFavourites;
        }),
        catchError((error) => {
          console.error('Error al obtener acciones favoritas:', error);
          return throwError(error); // Manejo de errores
        })
      );
  }

  // Método para obtener las acciones finalizadas de un usuario
  getUserCompleted(): Observable<any[]> {
    const language = this.translateService.getCurrentLanguage();
    const url = `${this.baseURL}${environment.api_actions}?channel_id=2&completed=1`;
    console.log('Request fav actions URL:', url);

    return this.http
      .get<any>(url, { headers: this.authService.getHeadersWithToken() })
      .pipe(
        map((response) => {
          // Mapea sobre todos los elementos en response.data y recoge todas las acciones favoritas
          const allFavourites = response.data.flatMap((entry: any) =>
            entry.action.map((action: any) => ({
              ...action,
              lang: action.lang[0][language.toUpperCase()],
            }))
          );

          return allFavourites;
        }),
        catchError((error) => {
          console.error('Error al obtener acciones favoritas:', error);
          return throwError(error); // Manejo de errores
        })
      );
  }
}
