import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TranslateService {
  private baseURL: string;
  private ENDPOINT_LANGUAGE = '/api/v1/language';
  private translations: any;
  private readonly DEFAULT_LANGUAGE = 'en'; 
  private languageSubject = new BehaviorSubject<string>(localStorage.getItem('userLanguage') || this.getBrowserLanguage());
  language$ = this.languageSubject.asObservable();

  constructor(private http: HttpClient, private authService: AuthService) {
    if (environment.local == true) {
      this.baseURL = `${environment.localUrl}:${environment.localPort}`;
    } else {
      this.baseURL = `${environment.url}`;
    }
    this.loadTranslations();
  }

  // Cargar las traducciones, usando el idioma del navegador si no hay nada en localStorage
  loadTranslations() {
    try {
      let userLanguage = localStorage.getItem('userLanguage');
      if (!userLanguage) {
        userLanguage = this.getBrowserLanguage();
      }
      this.loadTranslationFile(userLanguage);
    } catch (error) {
      console.error('Error loading translations:', error);
      this.loadDefaultTranslations(); // Fallback en caso de error
    }
  }

  // Método para cargar el archivo de traducción desde el servidor
  use(language: string): Observable<any> {
    const translationUrl = `/assets/i18n/${language}.json`;
    return this.http.get<any>(translationUrl).pipe(
      tap((translations) => {
        this.translations = translations;
        localStorage.setItem('userLanguage', language);
        this.languageSubject.next(language);
      }),
      catchError((error) => {
        console.error(`Error loading translation file for language ${language}:`, error);
        return of(null); // Retorna un observable vacío en caso de error
      })
    );
  }

  // Cargar el archivo de traducción especificado
  private loadTranslationFile(language: string) {
    try {
      const translationUrl = `/assets/i18n/${language}.json`;
      this.http.get<any>(translationUrl).subscribe(
        (translations) => {
          this.translations = translations;
        },
        (error) => {
          console.error(`Error loading translation file for language ${language}:`, error);
          this.loadBrowserLanguage(); // Cargar idioma del navegador en caso de error
        }
      );
    } catch (error) {
      console.error('Error loading translation file:', error);
      this.loadBrowserLanguage(); // Fallback en caso de error
    }
  }

  // Cargar el idioma del navegador
   loadBrowserLanguage() {
    try {
      const browserLanguage = this.getBrowserLanguage();
      const translationUrl = `/assets/i18n/${browserLanguage}.json`;
      this.http.get<any>(translationUrl).subscribe(
        (translations) => {
          this.translations = translations;
        },
        (error) => {
          console.error('Error loading browser language file:', error);
          this.loadDefaultTranslations(); // Cargar idioma por defecto en caso de error
        }
      );
    } catch (error) {
      console.error('Error loading browser language:', error);
      this.loadDefaultTranslations(); // Fallback en caso de error
    }
  }

  // Método para obtener el idioma por defecto de la empresa o el configurado
  private loadDefaultTranslations() {
    try {
      this.getCompanyLanguages().subscribe(
        (response: any) => {
          const defaultLanguage = response.data[0]?.language.find(
            (lang: any) => lang.default === 1
          );
          if (defaultLanguage) {
            this.loadTranslationFile(defaultLanguage.abbreviation);
          } else {
            this.loadTranslationFile(this.DEFAULT_LANGUAGE);
          }
        },
        (error) => {
          console.error('Error fetching company languages:', error);
          this.loadTranslationFile(this.DEFAULT_LANGUAGE); // Cargar idioma por defecto en caso de error
        }
      );
    } catch (error) {
      console.error('Error loading default translations:', error);
      this.loadTranslationFile(this.DEFAULT_LANGUAGE); // Fallback en caso de error
    }
  }

  // Obtener los idiomas soportados por la empresa
  getCompanyLanguages() {
    try {
      const headers = this.authService.getHeadersWithToken();
      const endpoint = `${this.baseURL}${this.ENDPOINT_LANGUAGE}?channel_id=2`;
      return this.http.get(endpoint, { headers }).pipe(
        catchError((error) => {
          console.error('Error fetching company languages:', error);
          return of([]); // Retorna un observable vacío en caso de error
        })
      );
    } catch (error) {
      console.error('Error fetching company languages:', error);
      return of([]); // Fallback en caso de error
    }
  }

  // Método para obtener el idioma del navegador
  public getBrowserLanguage(): string {
    return navigator.language.split('-')[0]; // 'en-US' -> 'en'
  }

  // Método para traducir una clave específica
  translate(key: string): string {
    try {
      if (!this.translations) {
        return key;
      }

      const parts = key.split('.');
      let translation = this.translations;
      for (const part of parts) {
        translation = translation[part];
        if (!translation) {
          return key;
        }
      }

      return translation;
    } catch (error) {
      console.error('Error during translation:', error);
      return key; // Fallback en caso de error
    }
  }

  // Método público para obtener el idioma actual
  getCurrentLanguage(): string {
    return this.languageSubject.value;
  }
}