import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from '@services/auth/auth.service';
import { environment } from 'environments/environment';
import { TranslateService } from '@services/translate/translate.service';
@Injectable({
  providedIn: 'root',
})
export class NoticiasService {
  baseURL: string;
  
  constructor(
    private authService: AuthService,    
    private http: HttpClient,
    private translateService: TranslateService
  ) {
    if (environment.local == true) {
      this.baseURL = `${environment.localUrl}:${environment.localPort}`;
    } else {
      // when is deployed
      this.baseURL = `${environment.url}`;
    }
  }

  getNews(page: number = 0, limit: number = 10): Observable<any[]> {
    const language = this.translateService.getCurrentLanguage();
    const url = `${this.baseURL}${environment.api_news}?channel_id=2&lang=${language}&page=${page}&limit=${limit}`;
    console.log('Request URL:', url);

    return this.http
      .get<any>(url, { headers: this.authService.getHeadersWithToken() })
      .pipe(
        map((response) => {
          return response.data[0].article.map((article: any) => ({
            id: article.id,
            date_start: article.date_start,  
            lang: article.lang.find((l: any) =>
              l.hasOwnProperty(language.toUpperCase())
            )[language.toUpperCase()],
          }));
        }),
        catchError((error) => {
          console.error('Error al obtener noticias:', error);
          return throwError(error); // Manejo de errores
        })
      );
  }
  getNoticiaById(noticiaId: string): Observable<any> {
    const language = this.translateService.getCurrentLanguage().toUpperCase();
    const url = `${this.baseURL}${environment.api_news}?channel_id=2&lang=${language}&id=${noticiaId}`;
    
    return this.http
      .get<any>(url, { headers: this.authService.getHeadersWithToken() })
      .pipe(
        map((response) => {
          const noticia = response.data[0]?.article[0];
          const langData = noticia?.lang.find((l: any) => l[language]) || noticia?.lang[0];
          noticia.lang = langData ? langData[language] || Object.values(langData)[0] : null;
          return noticia;
        }),
        catchError((error) => {
          console.error('Error al obtener la noticia:', error);
          return throwError(error);
        })
      );
  }
}
