<header-menu></header-menu>
<div class="main-wiki-container">
  <div class="title-underlined">
    <h2 class="head-title">
      {{ translateService.translate("WIKI.TITLE") }}
    </h2>
  </div>
  <div class="title-bg-image">
    <img src="../../../../assets/circles-bg-1.png" />
  </div>

  <!-- Spinner visible mientras isLoading sea true -->
  <mat-spinner *ngIf="isLoading"></mat-spinner>

  <!-- Contenido de la wiki visible cuando isLoading sea false -->
  <div class="wiki-container" *ngIf="!isLoading">
    <!-- Menú lateral con las secciones para dispositivos de escritorio -->
    <mat-nav-list class="navList desktop">
      <div class="listItemWrapper" *ngFor="let section of wikiItems">
        <mat-list-item
          class="listItem"
          [class.selected]="selectedSection === section"
          (click)="selectSection(section)"
        >
          <h3 matLine>{{ section.name }}</h3>
        </mat-list-item>
        <div class="listItemArrow"></div>
        <!-- Pico (elemento hermano) -->
      </div>
    </mat-nav-list>

    <!-- Menú desplegable para dispositivos móviles -->
    <mat-form-field appearance="outline" class="dropdown">
      <mat-icon>keyboard_arrow_down</mat-icon>
      <mat-select
        [(value)]="selectedSection"
        (selectionChange)="selectSection($event.value)"
        panelClass="menu-dropdown-styles"
      >
        <mat-option *ngFor="let section of wikiItems" [value]="section">
          {{ section.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <!-- Contenido dinámico basado en la selección de sección -->
    <div class="content">
      <div *ngIf="selectedSection">
        <h3 class="content-title">{{ selectedSection.name }}</h3>
        <div
          class="text-container"
          [innerHTML]="selectedSection.description"
        ></div>
      </div>
    </div>
  </div>

  <a routerLink="/inicio" class="backHome">
    <mat-icon class="arrow-icon">arrow_back</mat-icon>
    {{ translateService.translate("MAIN.LINKS.BACK_HOME") }}
  </a>
  <div class="content-powered-by">
    <img src="../../../../assets/powered-by-logo.png" />
  </div>
</div>