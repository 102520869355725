<div class="noticia-details-container">
  <header-menu></header-menu>

  <div class="content-container">
    <div
      class="noticia-container"
      [ngClass]="{ 'retos-details': isMainpage() }"
    >
      
      <!-- Spinner visible mientras isLoading sea true -->
      <mat-spinner *ngIf="isLoading"></mat-spinner>

      <!-- Contenido de la noticia visible cuando isLoading sea false -->
      <div *ngIf="!isLoading" class="noticia-content">
        <!-- News content section -->
        <div class="descripcion-noticia">
          <div class="title-underlined">
            <h2>{{ title }}</h2>
          </div>
          <img [src]="foto" alt="News image" />

          <!-- Container for the icon and date -->
          <div class="date-container">
            <mat-icon>calendar_month</mat-icon>
            <p class="dateP">{{ date }}</p>
          </div>
          <p [innerHTML]="description" class="description-container"></p>
          <br /><br />
          <a routerLink="/inicio" class="backHome text-bold">
            <mat-icon class="arrow-icon">arrow_back</mat-icon>
            {{ translateService.translate("MAIN.LINKS.BACK_HOME") }}
          </a>
        </div>
      </div>

      <!-- Challenges section and points request button -->
      <div
        class="retos-container generic-container"
        [ngClass]="{ 'retos-details': isMainpage() }"
      >
        <retos></retos>
        <br />
        <br />
        <div class="image-button-puntos" routerLink="/contacto/puntos">
          <div class="container-background"></div>
          <div class="container-text">
            <img src="../../../../assets/boton-solicitar-puntos.png" />
            <div class="h2-button-text">
              {{
                translateService.translate(
                  "CONTACT_FORM.WISH_TO_REQUEST_POINTS"
                )
              }}
            </div>
            <div class="h2-button-text-link">
              {{ translateService.translate("CONTACT_FORM.REQUEST") }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="image-button-gamba" [routerLink]="['/contacto/soporte']">
      <div class="container-background"></div>
      <div class="container-text">
        <img src="../../../../assets/boton-hablar-gamba.png" />
        <div class="h2-button-text">
          {{ translateService.translate("MAIN.BUTTONS.TALK_TO_THE_GAMBA") }}
        </div>
        <div class="h2-button-text-link">
          {{ translateService.translate("CONTACT_FORM.TALK_NOW") }}
        </div>
      </div>
    </div>
    <br /><br />
    <div class="content-powered-by">
      <img src="../../../../assets/powered-by-logo.png" />
    </div>
  </div>
</div>