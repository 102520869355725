import {
  SocialLoginModule,
  GoogleSigninButtonModule,
} from '@abacritt/angularx-social-login';
import { Component, OnInit, OnDestroy, ErrorHandler } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, RouterModule, RouterOutlet } from '@angular/router';
import { AngularMaterialModule } from 'app/module/material/material-module';
import { HttpClientModule } from '@angular/common/http';
import { AuthService } from '@services/auth/auth.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { TranslateService } from '@services/translate/translate.service';
import { catchError } from 'rxjs/internal/operators/catchError';
import { throwError } from 'rxjs';

@Component({
  selector: 'login',
  standalone: true,
  imports: [
    GoogleSigninButtonModule,
    SocialLoginModule,
    AngularMaterialModule,
    HttpClientModule,
    RouterOutlet,
    RouterModule,
  ],
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  authStateSubscription!: Subscription;
  public loginValid = true;
  username = '';
  password = '';
  loggedIn: any;
  appName = require('../../../../package.json').name;

  constructor(
    public translateService: TranslateService,
    private router: Router,
    private snackBar: MatSnackBar,
    private authService: AuthService,
    private errorHandler: ErrorHandler
  ) {}

  ngOnInit() {
    // Obtener el idioma del navegador y aplicarlo si no hay nada en localStorage
    const browserLanguage = this.translateService.getBrowserLanguage();
    const currentLanguage =
      localStorage.getItem('userLanguage') || browserLanguage;

    // Aplicar el idioma predeterminado
    this.translateService.use(currentLanguage).subscribe();

    this.authStateSubscription = this.authService.isAuth
      .pipe(
        catchError((err: any) => {
          console.log('Error occurred', err);
          return throwError(err);
        })
      )
      .subscribe(
        (isAuth) => {
          this.loggedIn = isAuth;
        },
        (err) => console.log('HTTP Error while performing the login', err),
        () => console.log('Login request completed')
      );
  }

  ngOnDestroy() {
    // Desuscribirse cuando el componente se destruya
    if (this.authStateSubscription) {
      this.authStateSubscription.unsubscribe();
    }
  }

  /**
   * local login workaround when not logging in against backend
   */
  login() {
    try {
      /*if (this.username === 'admin' && this.password.length > 0) {
        localStorage.clear();

        // Almacenar los datos del usuario localmente
        localStorage.setItem(
          'localUser',
          JSON.stringify({ firstName: this.username })
        );

        // Actualizar el estado de autenticación a "true"
        this.authService.isAuth.next(true);
        this.router.navigateByUrl('/inicio');
      } else {
        */
      this.loginValid = false;
      // Mostrar un snackbar de error si las credenciales son incorrectas
      this.snackBar.open(
        this.translateService.translate('LOGIN.ERRORS.WRONG_CREDENTIALS'),
        '',
        {
          duration: 5000,
          panelClass: ['error-snackbar'],
        }
      );
      //}
    } catch (error: any) {
      // Manejamos el error con el CustomErrorHandler y mostramos un snackbar
      this.errorHandler.handleError(error);

      // Además de manejar el error globalmente, mostramos el snackbar al usuario
      this.snackBar.open(
        this.translateService.translate('LOGIN.ERRORS.LOGIN_ERROR'),
        '',
        {
          duration: 5000,
          panelClass: ['error-snackbar'],
        }
      );
    }
  }
}
