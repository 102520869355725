import { AfterViewChecked, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { AngularMaterialModule } from 'app/module/material/material-module';
import { ParticipantesComponent } from './participantes/participantes.component';
import { NoticiasComponent } from './noticias/noticias.component';
import { RetosComponent } from './retos/retos.component';
import { NoticiaComponent } from './noticias/noticia/noticia.component';
import { WikiComponent } from './wiki/wiki.component';
import { ConfettiService } from '@services/confetti/confetti.service';
import { HeaderComponent } from '@components/header/header.component';
import { DataShareService } from '@services/data-share/data-share.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { ParticipantesService } from '@services/participantes/participantes.service';
import { TranslateService } from '@services/translate/translate.service';
import { catchError, throwError } from 'rxjs';

@Component({
  selector: 'inicio',
  standalone: true,
  imports: [
    AngularMaterialModule,
    HeaderComponent,
    ParticipantesComponent,
    NoticiasComponent,
    RetosComponent,
    NoticiaComponent,
    WikiComponent,
    RouterLink,
  ],
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit, AfterViewChecked {
  showMainSubscription!: Subscription;
  participantesData: any[] = [];
  userProfile: any;
  showWiki = false;
  handleNews = false;
  showBanner = true;

  constructor(
    public translateService: TranslateService,
    private dataShare: DataShareService,
    private confettiService: ConfettiService,
    private router: Router,
    private participantesService: ParticipantesService,
    private cdRef:ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    localStorage.removeItem('retoActivo');
    const socialUser = localStorage.getItem('socialUser');

    if (!socialUser) {
      //   this.router.navigateByUrl('/login');
    } else {
      // Obtener datos de participantes al iniciar el componente
      // this.loadParticipants();
    }
    this.showMainSubscription = this.dataShare
      .getUpdateShowMain()
      .subscribe((update: any) => {
        // updates the list
        if (update) {
          this.handleNews = update;
        }
      });
  }

  // using this hook method to avoid rendering issues while using some variables which are updated later on
  ngAfterViewChecked() {
    this.handleNews = false;
  }

  ngOnDestroy() {
    if (this.showMainSubscription) {
      this.showMainSubscription.unsubscribe();
    }
  }

  celebrate() {
    this.confettiService.celebrateConfetti();
  }

  loadParticipants(): void {
    this.participantesService
      .getUsuarios()
      .pipe(
        catchError((err: any) => {
          console.log('Error occurred', err);
          return throwError(err);
        })
      )
      .subscribe(
        (data) => {
          this.participantesData = data;
          console.log(this.participantesData);
          this.dataShare.updateRankingList(data);
        },
        (error) => {
          console.error('Error fetching users:', error);
        }
      );
  }

  isNotMainPage(): boolean {
    return this.router.url !== '/inicio';
  }

  getNewsUpdate(event: boolean) {
    this.handleNews = event;
    // Puedes agregar lógica adicional aquí si es necesario
  }
}
