import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { AuthService } from '@services/auth/auth.service';
import { TranslateService } from '@services/translate/translate.service';

@Injectable({
  providedIn: 'root',
})
export class ContactFormService {
  private baseURL: string;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private translateService: TranslateService
  ) {

    if (environment.local == true) {
      this.baseURL = `${environment.localUrl}:${environment.localPort}`;
    } else {
      // when is deployed
      this.baseURL = `${environment.url}`;
    }
  }

  sendPointsRequest(
    action_id: number,
    file: File | null = null,
    textArea: string = ''
  ): Observable<any> {
    const lang = this.translateService.getCurrentLanguage();
    const url = `${this.baseURL}${environment.api_request_points}&channel_id=2&action_id=${action_id}&lang=${lang}`;
    const headers = this.authService.getHeadersWithToken();

    const body: any = {};
    if (textArea) {
      body.textArea = textArea;
    }
    if (file) {
      body.file = file;
    }

    return this.http.post<any>(url, body, { headers }).pipe(
      catchError((error) => {
        console.error('Error al enviar el formulario de solicitud de puntos:', error);
        return throwError(error);
      })
    );
  }

  sendSupportRequest(
    textArea: string,
    file: File | null = null,
    action_id?: number 
  ): Observable<any> {
    const lang = this.translateService.getCurrentLanguage();
    let url = `${this.baseURL}${environment.api_support}?channel_id=2&lang=${lang}`;

    if (action_id !== undefined) {
      url += `&action_id=${action_id}`;
    }

    const headers = this.authService.getHeadersWithToken();

    const body: any = { textArea };
    if (file) {
      body.file = file;
    }

    return this.http.post<any>(url, body, { headers }).pipe(
      catchError((error) => {
        console.error('Error al enviar el formulario de soporte:', error);
        return throwError(error);
      })
    );
  }
}